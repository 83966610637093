import React from 'react'
import FocusLock from 'react-focus-lock'
import { useStaticQuery, graphql } from 'gatsby'

import Icon from './scaffolding/icon'
import Form from './form'

const Popup = ({ isOpen, toggleModal }) => {
  const data = useStaticQuery(graphql`
    {
      bg: file(relativePath: { eq: "Muze-bg_dots.svg" }) {
        publicURL
      }
      phone: file(relativePath: { eq: "Muze_Collage_Butterfly_phone.gif" }) {
        publicURL
      }
    }
  `)

  // page data
  const { bg, phone } = data

  return (
    <FocusLock disabled={!isOpen}>
      <div className={`modal${isOpen ? ' is-open' : ''}`}>
        <div className="modal--wrapper">
          <div
            className="modal--inner"
            style={{ backgroundImage: `url('${bg.publicURL}')` }}
          >
            <button
              onClick={() => {
                toggleModal(false)
              }}
              tab-index="0"
              role="button"
              aria-label="Close Sign-Up form"
              className="modal--close"
            >
              <Icon name="Close" color="#fff" />
            </button>

            <div className="modal--content">
              <div className="nokia-phone">
                <img
                  src={phone.publicURL}
                  alt="Nokia Phone with a butterfly on it"
                />
              </div>
              <p>
                Muze is a different way to a chat.
                <br />A better way to say whatever.
              </p>
              <p>
                Each conversation is a freeform canvas, where the boring,
                binary ways of messaging are elevated into something more
                collaborative and natural.
              </p>
              <p>Sign up for our newsletter.</p>
              <Form />
              <p>Or drop a line at <a  className="contact-link" href="mailto:info@muze.nyc">info@muze.nyc</a></p>
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  )
}

export default Popup
