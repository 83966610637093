import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import astrochimp from 'astrochimp'

const Form = () => {
  const [filled, setFilled] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [existing, setExisting] = useState(false)
  const { handleSubmit, register, reset, errors } = useForm()

  const resetForm = () => {
    setError(false)
    reset()
    setFilled(false)
  }

  const onSubmit = (data, e) => {
    setSubmitting(true)
    setError(false)

    astrochimp(
      'https://nyc.us10.list-manage.com/subscribe/post?u=cecf84dbea8799ccea8f781e4&amp;id=c6f257126e',
      data,
      (err, data) => {
        setSubmitting(false)

        if (err) {
          const subed = err.msg.includes('already subscribed to list')
          if (subed) {
            setError(false)
            setExisting(true)
          } else {
            setSuccess(false)
            setError(true)
          }
        } else {
          setError(false)
          setSuccess(true)
        }
      }
    )
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {!error && !success && !existing && (
        <>
          <div className="form-row">
            <div className={`control${filled ? ' is-filled' : ''}`}>
              <label htmlFor="formEmail" className="control--label">
                example@gmail.com
              </label>
              <input
                id="formEmail"
                name="EMAIL"
                type="email"
                inputMode="email"
                autoComplete="email"
                ref={register({
                  required: 'Can we get an email first?',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'invalid email address',
                  },
                })}
                onFocus={(e) => {
                  setFilled(true)
                }}
                onBlur={(e) => {
                  const value = e.target.value
                  setFilled(value ? true : false)
                }}
              />
            </div>
            <button
              type="submit"
              className="btn form--submit"
              style={{ minWidth: '7em' }}
            >
              {submitting ? 'Wait...' : 'Sign Up'}
            </button>
          </div>
          {errors.EMAIL && (
            <span className="control--error">{errors.EMAIL.message}</span>
          )}
        </>
      )}

      {success && (
        <div className="form--success">
          <div className="form--success-inner">
            <p>Dope, look out for an invite soon!</p>
          </div>
        </div>
      )}

      {existing && (
        <div className="form--success">
          <div className="form--success-inner">
            <p>Thanks, but you’re already signed up!</p>
          </div>
        </div>
      )}

      {error && (
        <div className="form--error">
          <div className="form--error-inner">
            <p>
              Uh-oh... that didn’t work{' '}
              <button className="btn" onClick={resetForm}>
                try again
              </button>
            </p>
          </div>
        </div>
      )}
    </form>
  )
}

export default Form
