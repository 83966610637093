import React from 'react'

const footer = () => {
  return (
    <footer className="footer" role="contentinfo">
      <div className="footer--wrapper">
        <div className="footer--content"></div>
      </div>
    </footer>
  )
}

export default footer
