import React, { useState, useCallback, useEffect } from 'react'
import useEventListener from '@use-it/event-listener'

import cursorIcon from '../img/cursor.svg'

const Cursor = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [coords, setCoords] = useState({ x: 0, y: 0 })

  const onMouseEnter = useCallback(() => setIsVisible(true), [])
  const onMouseLeave = useCallback(() => setIsVisible(false), [])

  const onMouseMove = useCallback(({ clientX, clientY }) => {
    setCoords({ x: clientX, y: clientY })
  }, [])

  const setDefaultCursor = (state) => {
    if (typeof document !== `undefined`) {
      console.log(`state: ${state}`)
      setIsVisible(!state)
      document.body.classList.toggle('default-cursor', state)
    }
  }

  if (typeof document !== `undefined`) {
    useEventListener('mousemove', onMouseMove, document)
    useEventListener('mouseenter', onMouseEnter, document)
    useEventListener('mouseleave', onMouseLeave, document)
  }

  useEffect(() => {
    document.querySelectorAll('.has-default-cursor').forEach((item) => {
      item.addEventListener('mouseenter', () => {
        setDefaultCursor(true)
      })
      item.addEventListener('mouseleave', () => {
        setDefaultCursor(false)
      })
    })

    return () => {
      document.querySelectorAll('.has-default-cursor').forEach((item) => {
        item.removeEventListener('mouseenter', setDefaultCursor(true))
        item.removeEventListener('mouseleave', setDefaultCursor(false))
      })
    }
  }, [])

  return (
    <div
      className={`cursor${isVisible ? ' is-visible' : ''}`}
      style={{ top: coords.y, left: coords.x }}
    >
      <img src={cursorIcon} alt="Pencil Icon" />
    </div>
  )
}

export default Cursor
