import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setMobile } from '../state/global'

// site layout
import SEO from '../components/scaffolding/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import Cursor from '../components/cursor'
import DrawingLayer from '../components/drawingLayer'

import cursor from '../img/cursor.svg'
import Popup from '../components/popup'

import { pushClassname } from '../lib/pushClassname'
import styles from './index.module.css'
import bgWide from '../img/bg-wide.jpg'
import bgPortrait from '../img/bg-portrait.jpg'

const indexPage = ({ dispatch, mobile }) => {
  React.useEffect(pushClassname(styles.singlePageApp, 'body'), [])
  React.useEffect(pushClassname(styles.singlePageApp, 'html'), [])
  React.useEffect(pushClassname(styles.pageWrapperScrollOverrides, '.page-wrapper'), [])

  const [isOpen, setOpen] = useState(false)

  const toggleModal = (state) => {
    setOpen(state)
    if (typeof document !== `undefined`) {
      document.body.classList.toggle('popup-open', state)
    }
  }

  const _setHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  useEffect(() => {
    _onWindowResize()
    window.addEventListener('resize', _onWindowResize)
    _setHeight()
  })

  const _onWindowResize = () => {
    dispatch(setMobile(document.documentElement.clientWidth < 32 * 16))
    _setHeight()
  }

  let video = mobile ? 447960216 : 445004881
  let placeholderImg = mobile ? bgPortrait : bgWide

  return (
    <>
      <SEO description="A creative messaging app that expands how you can communicate." />
      <Cursor />
      <Header
        className="header"
        toggleModal={toggleModal}
      />

      <main role="main" id="content">
        <DrawingLayer />
        <div className="video-container">
          <img className="placeholder" src={placeholderImg} />
          <iframe
            title="rad"
            src={`https://player.vimeo.com/video/${video}?background=1&autoplay=1&loop=1&byline=0&title=0`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </main>

      <Footer />

      <Popup isOpen={isOpen} toggleModal={toggleModal} />
    </>
  )
}

export default connect(
  (state) => ({
    mobile: state.global.mobile,
  }),
  null
)(indexPage)
