import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import bubble_0 from '../img/_click/M.svg'
import bubble_1 from '../img/_click/U.svg'
import bubble_2 from '../img/_click/Z.svg'
import bubble_3 from '../img/_click/E.svg'
import bubble_4 from '../img/_click/muze_bubble_0.svg'
import bubble_5 from '../img/_click/muze_bubble_1.svg'
import bubble_6 from '../img/_click/sloth.svg'
import bubble_7 from '../img/_click/muze_bubble_2.svg'
import bubble_8 from '../img/_click/plant.svg'
import bubble_9 from '../img/_click/muze_bubble_3.svg'
import bubble_10 from '../img/_click/muze_bubble_4.svg'
import bubble_11 from '../img/_click/hug.svg'
import bubble_12 from '../img/_click/muze_bubble_5.svg'
import bubble_13 from '../img/_click/muze_bubble_6.svg'
import bubble_14 from '../img/_click/hand.svg'
import bubble_15 from '../img/_click/muze_bubble_7.svg'
import bubble_16 from '../img/_click/flowers.svg'
import bubble_17 from '../img/_click/muze_bubble_8.svg'
import bubble_18 from '../img/_click/eyeroll.svg'
import bubble_19 from '../img/_click/muze_bubble_9.svg'
import bubble_20 from '../img/_click/couch.svg'
import bubble_21 from '../img/_click/muze_bubble_10.svg'
import bubble_22 from '../img/_click/bird.svg'
import bubble_23 from '../img/_click/muze_bubble_11.svg'
import bubble_24 from '../img/_click/clouds.svg'
import bubble_25 from '../img/_click/muze_bubble_12.svg'
import bubble_26 from '../img/_click/cloud.svg'
import bubble_27 from '../img/_click/muze_bubble_13.svg'
import bubble_28 from '../img/_click/catterpillar.svg'
import bubble_29 from '../img/_click/clouds2.svg'
import bubble_30 from '../img/_click/muze_bubble_14.svg'
import bubble_31 from '../img/_click/muze_bubble_15.svg'
import bubble_32 from '../img/_click/muze_bubble_16.svg'
import bubble_33 from '../img/_click/muze_bubble_17.svg'
import bubble_34 from '../img/_click/muze_bubble_18.svg'

import mobile_bubble_0 from '../img/_click/M_m.svg'
import mobile_bubble_1 from '../img/_click/U_m.svg'
import mobile_bubble_2 from '../img/_click/Z_m.svg'
import mobile_bubble_3 from '../img/_click/E_m.svg'
import mobile_bubble_4 from '../img/_click/muze_bubble_0-mobile.svg'
import mobile_bubble_5 from '../img/_click/muze_bubble_1-mobile.svg'
import mobile_bubble_6 from '../img/_click/sloth_m.svg'
import mobile_bubble_7 from '../img/_click/muze_bubble_2-mobile.svg'
import mobile_bubble_8 from '../img/_click/plant_m.svg'
import mobile_bubble_9 from '../img/_click/muze_bubble_3-mobile.svg'
import mobile_bubble_10 from '../img/_click/muze_bubble_4-mobile.svg'
import mobile_bubble_11 from '../img/_click/hug_m.svg'
import mobile_bubble_12 from '../img/_click/muze_bubble_5-mobile.svg'
import mobile_bubble_13 from '../img/_click/muze_bubble_6-mobile.svg'
import mobile_bubble_14 from '../img/_click/hand_m.svg'
import mobile_bubble_15 from '../img/_click/muze_bubble_7-mobile.svg'
import mobile_bubble_16 from '../img/_click/flowers_m.svg'
import mobile_bubble_17 from '../img/_click/muze_bubble_8-mobile.svg'
import mobile_bubble_18 from '../img/_click/eyeroll_m.svg'
import mobile_bubble_19 from '../img/_click/muze_bubble_9-mobile.svg'
import mobile_bubble_20 from '../img/_click/couch_m.svg'
import mobile_bubble_21 from '../img/_click/muze_bubble_10-mobile.svg'
import mobile_bubble_22 from '../img/_click/bird_m.svg'
import mobile_bubble_23 from '../img/_click/muze_bubble_11-mobile.svg'
import mobile_bubble_24 from '../img/_click/clouds_m.svg'
import mobile_bubble_25 from '../img/_click/muze_bubble_12-mobile.svg'
import mobile_bubble_26 from '../img/_click/cloud_m.svg'
import mobile_bubble_27 from '../img/_click/muze_bubble_13-mobile.svg'
import mobile_bubble_28 from '../img/_click/catterpillar_m.svg'
import mobile_bubble_29 from '../img/_click/clouds2_m.svg'
import mobile_bubble_30 from '../img/_click/muze_bubble_14-mobile.svg'
import mobile_bubble_31 from '../img/_click/muze_bubble_15-mobile.svg'
import mobile_bubble_32 from '../img/_click/muze_bubble_16-mobile.svg'
import mobile_bubble_33 from '../img/_click/muze_bubble_17-mobile.svg'
import mobile_bubble_34 from '../img/_click/muze_bubble_18-mobile.svg'

const DrawingLayer = ({ mobile }) => {
  const canvasRef = useRef(null)
  const imgArray = [
    bubble_0,
    bubble_1,
    bubble_2,
    bubble_3,
    bubble_4,
    bubble_5,
    bubble_6,
    bubble_7,
    bubble_8,
    bubble_9,
    bubble_10,
    bubble_11,
    bubble_12,
    bubble_13,
    bubble_14,
    bubble_15,
    bubble_16,
    bubble_17,
    bubble_18,
    bubble_19,
    bubble_20,
    bubble_21,
    bubble_22,
    bubble_23,
    bubble_24,
    bubble_25,
    bubble_26,
    bubble_27,
    bubble_28,
    bubble_29,
    bubble_30,
    bubble_31,
    bubble_32,
    bubble_33,
    bubble_34
  ]
  const imgArrayMobile = [
    mobile_bubble_0,
    mobile_bubble_1,
    mobile_bubble_2,
    mobile_bubble_3,
    mobile_bubble_4,
    mobile_bubble_5,
    mobile_bubble_6,
    mobile_bubble_7,
    mobile_bubble_8,
    mobile_bubble_9,
    mobile_bubble_10,
    mobile_bubble_11,
    mobile_bubble_12,
    mobile_bubble_13,
    mobile_bubble_14,
    mobile_bubble_15,
    mobile_bubble_16,
    mobile_bubble_17,
    mobile_bubble_18,
    mobile_bubble_19,
    mobile_bubble_20,
    mobile_bubble_21,
    mobile_bubble_22,
    mobile_bubble_23,
    mobile_bubble_24,
    mobile_bubble_25,
    mobile_bubble_26,
    mobile_bubble_27,
    mobile_bubble_28,
    mobile_bubble_29,
    mobile_bubble_30,
    mobile_bubble_31,
    mobile_bubble_32,
    mobile_bubble_33,
    mobile_bubble_34
  ]
  const colorWheel = [
    '#ff0000',
    '#0000FF',
    '#FFFF00',
    '#FF6600',
    '#00FF00',
    '#6600FF',
    '#ffffff',
    '#000000',
    '#ecc86f',
    '#c0e7ed',
    '#ececfc',
    '#ebfbf4',
    '#101725',
    '#c8e6ec',
    '#df6359',
    '#e6c87c'
  ]
  let [canvasHeight, setCanvasHeight] = useState(null)
  let [canvasWidth, setCanvasWidth] = useState(null)
  let [canvas, setCanvas] = useState(null)
  let [ctx, setCtx] = useState(null)
  let [colorIdx, setColorIdx] = useState(0)
  let [imgIdx, setImgIdx] = useState(0)
  let [pos, setPos] = useState({ x: null, y: null })
  let [isDrawing, setIsDrawing] = useState(false)
  let [lines, setLines] = useState([])
  let [lineIdx, setLineIdx] = useState(0)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    setCanvas(canvas)
    setCtx(context)
    if (typeof window !== null) {
      setCanvasHeight(document.body.getBoundingClientRect().height)
      setCanvasWidth(document.body.getBoundingClientRect().width)
    }
  }, [colorIdx])

  function _advanceColor() {
    colorIdx === colorWheel.length - 1
      ? setColorIdx(0)
      : setColorIdx(colorIdx => colorIdx + 1)
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  function _createImage(x, y) {
    let img = new Image()
    img.src = mobile ? imgArrayMobile[imgIdx] : imgArray[imgIdx]
    imgIdx === imgArray.length - 1
      ? setImgIdx(0)
      : setImgIdx(imgIdx => imgIdx + 1)
    _animateImage(img, x - 50, y - 50)
  }

  const _animateImage = (img, posX, posY) => {
    img.onload = function() {
      ctx.drawImage(img, posX, posY)
    }
  }

  function _onMouseDown(e) {
    _advanceColor()
    setIsDrawing(false)
    setPos({ x: e.clientX, y: e.clientY })
  }

  function _onMouseUp(e) {
    if (!isDrawing) {
      _createImage(e.clientX, e.clientY)
    }
    setIsDrawing(false)
    setLineIdx(lineIdx => lineIdx + 1)
  }

  function _onMouseMove(e) {
    if (e.clientX !== pos.x && e.clientY !== pos.y) {
      setIsDrawing(true)
      _draw(e.buttons, e.clientX, e.clientY)
    }
  }

  function _onTouchStart(e) {
    _advanceColor()
    setIsDrawing(false)
    setPos({ x: e.clientX, y: e.clientY })
  }

  function _onTouchMove(e) {
    if (e.touches[0].clientX !== pos.x && e.touches[0].clientY !== pos.y) {
      setIsDrawing(true)
      _draw(e, e.touches[0].clientX, e.touches[0].clientY)
    }
  }
  function _onTouchEnd(e) {
    setIsDrawing(false)
  }

  function _draw(buttons = null, clientX, clientY) {
    if (buttons !== 1 && !mobile) return
    let rect = canvas.getBoundingClientRect()
    ctx.lineWidth = 8
    ctx.shadowBlur = 0
    ctx.lineJoin = ctx.lineCap = 'round'
    ctx.strokeStyle = colorWheel[colorIdx]
    ctx.shadowColor = colorWheel[colorIdx]
    setLines(lines => [
      ...lines,
      {
        index: lineIdx,
        point: { x: pos.x, y: pos.y },
        color: colorWheel[colorIdx]
      }
    ])
    ctx.beginPath()
    ctx.moveTo(pos.x - 0.1, pos.y - 0.1)
    ctx.lineTo(clientX - rect.left, clientY - rect.top)
    setPos({ x: clientX - rect.left, y: clientY - rect.top })
    ctx.stroke()
    ctx.closePath()
  }

  return (
    <div className="canvas-el">
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        onMouseDown={_onMouseDown.bind(this)}
        onMouseUp={_onMouseUp.bind(this)}
        onMouseMove={_onMouseMove.bind(this)}
        onTouchStart={_onTouchStart.bind(this)}
        onTouchMove={_onTouchMove.bind(this)}
        onTouchEnd={_onTouchEnd.bind(this)}
      ></canvas>
    </div>
  )
}

export default connect(
  state => ({
    mobile: state.global.mobile
  }),
  null
)(DrawingLayer)
